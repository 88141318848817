import React, { useCallback, useEffect, useState } from 'react';

import { TimerBar } from '@components';

const DealsTimerBar = ({ timerBarProps }) => {
  const isSSR = typeof window === 'undefined';

  const [isHidden, setIsHidden] = useState(true);

  const handleScroll = useCallback(() => {
    if (!isSSR) {
      if (window.scrollY > 600) {
        setIsHidden(false);
      } else {
        setIsHidden(true);
      }
    }
  }, [isSSR]);

  useEffect(() => {
    handleScroll();

    window.addEventListener('scroll', handleScroll, true);
    return () => window.removeEventListener('scroll', handleScroll, true);
  }, [handleScroll]);

  return <TimerBar fixed isHidden={isHidden} {...timerBarProps} />;
};

export default DealsTimerBar;
